<template>
  <div ref="loginPage">Logging you in....</div>
</template>

<script>

export default {
  name: "Login",
  mounted() {
    this.$refs.loginPage.focus();
  },
  created() {
    let desired_path = localStorage.getItem("desired_path");
    if (desired_path) {
      localStorage.setItem("desired_path", "");
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
